import React, { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { Spin } from 'antd'
import Layout from '@/layout'
import Home from '@/pages/Home'

interface Props {
  children: React.ReactNode
}

export interface RouteObject {
  caseSensitive?: boolean
  children?: RouteObject[]
  element?: React.ReactNode
  index?: boolean
  path?: string
  header?: boolean
  sideBar?: boolean
  footer?: boolean
  meta?: {
    title: string
    key: string
    access?: string | string[] // 权限字符
    isLink?: string
    type?: string
  }
  icon?: string
}

// * 首页地址（默认）
export const HOME_URL = '/'

// * Tabs（黑名单地址，不需要添加到 tabs 的路由地址，暂时没用）
export const TABS_BLACK_LIST = [
  '/403',
  '/404',
  '/500',
  '/layout',
  '/login',
  '/dataScreen',
  '/active-level',
  '/entry-form'
]

const Conversation = lazy(() => import('@/pages/Conversation'))
const Review = lazy(() => import('@/pages/Review'))
const Login = lazy(() => import('@/pages/Login'))
const Page404 = lazy(() => import('@/pages/Page404'))
const Models = lazy(() => import('@/pages/Models'))
const Tag = lazy(() => import('@/pages/Tag'))
const ReviewPlan = lazy(() => import('@/pages/ReviewPlan'))
const FeatureTag = lazy(() => import('@/pages/FeatureTag'))
const VerifyCode = lazy(() => import('@/pages/VerifyCode'))
const Users = lazy(() => import('@/pages/Users'))
const Accounts = lazy(() => import('@/pages/Accounts'))
const ChatterModels = lazy(() => import('@/pages/ChatterModels'))
const Orders = lazy(() => import('@/pages/Orders'))
const Chatter = lazy(() => import('@/pages/Chatter'))
const Report = lazy(() => import('@/pages/Report'))
const SystemConfig = lazy(() => import('@/pages/SystemConfig'))
const Administrator = lazy(() => import('@/pages/Administrator'))
const UserDiscard = lazy(() => import('@/pages/UserDiscard'))
const ActiveLevel = lazy(() => import('@/pages/ActiveLevel'))
const HoneyLevel = lazy(() => import('@/pages/HoneyLevel'))
const Payroll = lazy(() => import('@/pages/Payroll'))
const SalaryData = lazy(() => import('@/pages/SalaryData'))
const ConversationPeople = lazy(() => import('@/pages/ConversationPeople'))
const ReviewPeople = lazy(() => import('@/pages/ReviewPeople'))
const QualityInspection = lazy(() => import('@/pages/QualityInspection'))
const QualityInspectionV2 = lazy(() => import('@/pages/QualityInspectionV2'))
const QualityInspectionHistory = lazy(() => import('@/pages/QualityInspectionHistory'))
const SessionData = lazy(() => import('@/pages/SessionData'))
const Log = lazy(() => import('@/pages/Log'))
const Test = lazy(() => import('@/pages/Test'))
const Train = lazy(() => import('@/pages/Train'))
const Guide = lazy(() => import('@/pages/Guide'))
const InstantMessage = lazy(() => import('@/pages/InstantMessage'))
const PopUpRecord = lazy(() => import('@/pages/PopUpRecord'))
const Banned = lazy(() => import('@/pages/Banned'))
// const OnlineData = lazy(() => import('@/pages/OnlineData'))
const AiTemplate = lazy(() => import('@/pages/AiTemplate'))
const Majiabao = lazy(() => import('@/pages/Majiabao'))
const SalaryDataBI = lazy(() => import('@/pages/SalaryDataBI'))
const OnlineTimeBi = lazy(() => import('@/pages/OnlineTimeBi'))
const Grab = lazy(() => import('@/pages/Grab'))
const PacketConfiguration = lazy(() => import('@/pages/PacketConfiguration'))
const TrafficDistribution = lazy(() => import('@/pages/TrafficDistribution'))
const WorkData = lazy(() => import('@/pages/WorkData'))
const SystemBootPrompt = lazy(() => import('@/pages/SystemBootPrompt'))
const PpvSendLimits = lazy(() => import('@/pages/PpvSendLimits'))
const QcProgressBar = lazy(() => import('@/pages/QcProgressBar'))
const CoreData = lazy(() => import('@/pages/CoreData'))
const SKU = lazy(() => import('@/pages/SKU'))
const QualityInspectionAI = lazy(() => import('@/pages/QualityInspectionAI'))
const ChatterToken = lazy(() => import('@/pages/ChatterToken'))
const ChatterEntry = lazy(() => import('@/pages/ChatterEntry'))
const ChatterEntryForm = lazy(() => import('@/pages/ChatterEntryForm'))
const ChatterEntryCheck = lazy(() => import('@/pages/ChatterEntryCheck'))

const PageLoading = ({ children }: Props) => {
  return (
    <Suspense
      fallback={
        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spin size='large' />
        </div>
      }
    >
      {children}
    </Suspense>
  )
}

// 页面路由表
export const routerArray: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    meta: {
      title: 'Layout',
      key: 'Layout'
    },
    children: [
      {
        index: true,
        element: (
          <PageLoading>
            <Home />
          </PageLoading>
        ),
        meta: {
          title: 'Home',
          key: 'Home'
        }
      },
      {
        path: 'Payroll',
        element: (
          <PageLoading>
            <Payroll />
          </PageLoading>
        ),
        meta: {
          title: '薪资条',
          key: 'payroll',
          access: 'payroll_index'
        }
      },
      {
        path: 'Conversation',
        element: (
          <PageLoading>
            <Conversation />
          </PageLoading>
        ),
        meta: {
          title: '会话列表(Model)',
          key: 'conversation',
          access: 'date_conversation_index'
        }
      },
      {
        path: 'conversation-people',
        element: (
          <PageLoading>
            <ConversationPeople />
          </PageLoading>
        ),
        meta: {
          title: '会话列表(素人)',
          key: 'conversation-people',
          access: 'normal_date_conversation_index'
        }
      },
      {
        path: 'quality-inspection',
        element: (
          <PageLoading>
            <QualityInspection />
          </PageLoading>
        ),
        meta: {
          title: '质检',
          key: 'quality-inspection',
          access: 'date_conversation_audit_index'
        }
      },
      {
        path: 'quality-inspection-v2',
        element: (
          <PageLoading>
            <QualityInspectionV2 />
          </PageLoading>
        ),
        meta: {
          title: '质检(V2)',
          key: 'quality-inspection-v2',
          access: 'v2_date_conversation_audit_index'
        }
      },
      {
        path: 'quality-inspection-history',
        element: (
          <PageLoading>
            <QualityInspectionHistory />
          </PageLoading>
        ),
        meta: {
          title: '质检修改历史',
          key: 'quality-inspection-history',
          access: 'v2_date_conversation_audit_history_index'
        }
      },
      {
        path: 'quality-inspection-ai',
        element: (
          <PageLoading>
            <QualityInspectionAI />
          </PageLoading>
        ),
        meta: {
          title: 'GPT质检规则',
          key: 'quality-inspection-ai',
          access: 'ai_audit_rule_index'
        }
      },
      {
        path: 'Accounts',
        element: (
          <PageLoading>
            <Accounts />
          </PageLoading>
        ),
        meta: {
          title: 'Accounts',
          key: 'Accounts',
          access: 'account_index'
        }
      },
      {
        path: 'ChatterModels',
        element: (
          <PageLoading>
            <ChatterModels />
          </PageLoading>
        ),
        meta: {
          title: 'ChatterModels',
          key: 'ChatterModels',
          access: 'chatter_model_index'
        }
      },
      {
        path: 'Chatters',
        element: (
          <PageLoading>
            <Chatter />
          </PageLoading>
        ),
        meta: {
          title: 'Chatters',
          key: 'Chatters',
          access: 'chatter_index'
        }
      },
      {
        path: 'ChatterEntry',
        element: (
          <PageLoading>
            <ChatterEntry />
          </PageLoading>
        ),
        meta: {
          title: '入职信息',
          key: 'ChatterEntry',
          access: 'chatter_entry_index'
        }
      },
      {
        path: 'ChatterToken',
        element: (
          <PageLoading>
            <ChatterToken />
          </PageLoading>
        ),
        meta: {
          title: 'Chatter踢出登录',
          key: 'ChatterToken',
          access: 'chatter_token'
        }
      },
      {
        path: 'review/:id',
        element: (
          <PageLoading>
            <Review />
          </PageLoading>
        ),
        meta: {
          title: '审核列表(Model)',
          key: 'review',
          access: 'date_conversation_message_index'
        }
      },
      {
        path: 'review-people/:id',
        element: (
          <PageLoading>
            <ReviewPeople />
          </PageLoading>
        ),
        meta: {
          title: '审核列表(素人)',
          key: 'review-people',
          access: 'normal_date_conversation_message_index'
        }
      },
      {
        path: 'model',
        element: (
          <PageLoading>
            <Models />
          </PageLoading>
        ),
        meta: {
          title: 'Models',
          key: 'Models',
          access: 'model_index'
        }
      },
      {
        path: 'tag',
        element: (
          <PageLoading>
            <Tag />
          </PageLoading>
        ),
        meta: {
          title: '标签管理',
          key: 'tag',
          access: 'tag_index'
        }
      },
      {
        path: 'plan',
        element: (
          <PageLoading>
            <ReviewPlan />
          </PageLoading>
        ),
        meta: {
          title: '评论计划',
          key: 'plan',
          access: 'tumbsup_notify_index'
        }
      },
      {
        path: 'feature-tag',
        element: (
          <PageLoading>
            <FeatureTag />
          </PageLoading>
        ),
        meta: {
          title: 'Feature Tags',
          key: 'feature-tag',
          access: 'feature_tag_index'
        }
      },
      {
        path: 'verify-code',
        element: (
          <PageLoading>
            <VerifyCode />
          </PageLoading>
        ),
        meta: {
          title: 'Verify Codes',
          key: 'verify-code',
          access: 'verify_code_index'
        }
      },
      {
        path: 'user',
        element: (
          <PageLoading>
            <Users />
          </PageLoading>
        ),
        meta: {
          title: 'Users',
          key: 'Users',
          access: 'user_index'
        }
      },
      {
        path: 'order',
        element: (
          <PageLoading>
            <Orders />
          </PageLoading>
        ),
        meta: {
          title: 'Orders',
          key: 'order',
          access: 'order_index'
        }
      },
      {
        path: 'report',
        element: (
          <PageLoading>
            <Report />
          </PageLoading>
        ),
        meta: {
          title: 'Report',
          key: 'Report',
          access: 'report_index'
        }
      },
      {
        path: 'system-config',
        element: (
          <PageLoading>
            <SystemConfig />
          </PageLoading>
        ),
        meta: {
          title: 'SystemConfig',
          key: 'SystemConfig',
          access: 'system_config_index'
        }
      },
      {
        path: 'administrator',
        element: (
          <PageLoading>
            <Administrator />
          </PageLoading>
        ),
        meta: {
          title: 'Administrator',
          key: 'administrator',
          access: 'administrator'
        }
      },
      {
        path: 'user-discard',
        element: (
          <PageLoading>
            <UserDiscard />
          </PageLoading>
        ),
        meta: {
          title: 'UserDiscard',
          key: 'UserDiscard',
          access: 'discarded_user_index'
        }
      },
      {
        path: 'packet-configuration',
        element: (
          <PageLoading>
            <PacketConfiguration />
          </PageLoading>
        ),
        meta: {
          title: 'packet configuration',
          key: 'packet-configuration',
          access: 'profile_field_options'
        }
      },
      {
        path: 'log',
        element: (
          <PageLoading>
            <Log />
          </PageLoading>
        ),
        meta: {
          title: '操作日志',
          key: 'log',
          access: 'audit_log_index'
        }
      },
      {
        path: 'grab',
        element: (
          <PageLoading>
            <Grab />
          </PageLoading>
        ),
        meta: {
          title: 'Grab',
          key: 'Grab',
          access: 'chatter_grab_configs'
        }
      },
      {
        path: 'traffic-distribution',
        element: (
          <PageLoading>
            <TrafficDistribution />
          </PageLoading>
        ),
        meta: {
          title: '流量分发',
          key: 'traffic-distribution',
          access: 'chatter_limit_config_index'
        }
      },
      {
        path: 'data-product',
        meta: {
          title: '数据产品',
          key: 'UserDiscard'
        },
        children: [
          {
            path: 'salary-data-bi',
            element: (
              <PageLoading>
                <SalaryDataBI />
              </PageLoading>
            ),
            meta: {
              title: 'SalaryDataBI',
              key: 'bi'
            }
          },
          {
            path: 'online-time-bi',
            element: (
              <PageLoading>
                <OnlineTimeBi />
              </PageLoading>
            ),
            meta: {
              title: 'OnlineTimeBi',
              key: 'online_time_bi'
            }
          },
          {
            path: 'session-data',
            element: (
              <PageLoading>
                <SessionData />
              </PageLoading>
            ),
            meta: {
              title: '会话数据',
              key: 'UserDiscard',
              access: 'date_conversation_stat_index'
            }
          }
          // {
          //   path: 'online-data',
          //   element: (
          //     <PageLoading>
          //       <OnlineData />
          //     </PageLoading>
          //   ),
          //   meta: {
          //     title: '在线数据表',
          //     key: 'online-data',
          //     access: 'online_stat_index'
          //   }
          // }
        ]
      },
      {
        path: 'test',
        element: (
          <PageLoading>
            <Test />
          </PageLoading>
        ),
        meta: {
          title: 'test',
          key: 'test',
          access: 'data_operation'
        }
      },
      {
        path: 'train-center',
        meta: {
          title: '培训中心',
          key: 'UserDiscard'
        },
        children: [
          {
            path: 'guide',
            element: (
              <PageLoading>
                <Guide />
              </PageLoading>
            ),
            meta: {
              title: '指引弹窗',
              key: 'UserDiscard',
              access: 'guide_popup_index'
            }
          },
          {
            path: 'train',
            element: (
              <PageLoading>
                <Train />
              </PageLoading>
            ),
            meta: {
              title: '培训弹窗',
              key: 'UserDiscard',
              access: 'train_popup_index'
            }
          }
        ]
      },
      {
        path: 'assistant',
        meta: {
          title: '小助手消息',
          key: 'assistant'
        },
        children: [
          {
            path: 'instant-message',
            element: (
              <PageLoading>
                <InstantMessage />
              </PageLoading>
            ),
            meta: {
              title: '即时消息',
              key: 'instant',
              access: 'message_task_index'
            }
          }
        ]
      },
      {
        path: 'pop-up-record',
        element: (
          <PageLoading>
            <PopUpRecord />
          </PageLoading>
        ),
        meta: {
          title: '挂机弹窗',
          key: 'pop-up-record',
          access: 'suspend_popup_index'
        }
      },
      {
        path: 'ai-template',
        element: (
          <PageLoading>
            <AiTemplate />
          </PageLoading>
        ),
        meta: {
          title: 'AI模版列表',
          key: 'ai-template',
          access: 'ai-template_index'
        }
      },
      {
        path: 'majiabao',
        element: (
          <PageLoading>
            <Majiabao />
          </PageLoading>
        ),
        meta: {
          title: '马甲包',
          key: 'majiabao',
          access: 'app_config'
        }
      },
      {
        path: 'banne',
        element: (
          <PageLoading>
            <Banned />
          </PageLoading>
        ),
        meta: {
          title: '封禁管理',
          key: 'banne',
          access: 'message_task_index'
        }
      },
      {
        path: 'system-boot-prompt',
        element: (
          <PageLoading>
            <SystemBootPrompt />
          </PageLoading>
        ),
        meta: {
          title: '小灰条提醒',
          key: 'SystemBootPrompt',
          access: 'tips_notify_configs_index'
        }
      },
      {
        path: 'qc-progress-bar',
        element: (
          <PageLoading>
            <QcProgressBar />
          </PageLoading>
        ),
        meta: {
          title: 'QC进度条',
          key: 'QcProgressBar',
          access: 'system_config_qc_progress'
        }
      },
      {
        path: 'ppv-send-limits',
        element: (
          <PageLoading>
            <PpvSendLimits />
          </PageLoading>
        ),
        meta: {
          title: 'PPV发送限制',
          key: 'PpvSendLimits',
          access: 'system_config_ppv_send_limitation'
        }
      },
      {
        path: 'sku',
        element: (
          <PageLoading>
            <SKU />
          </PageLoading>
        ),
        meta: {
          title: 'sku',
          key: 'sku',
          access: 'skus_index'
        }
      }
    ]
  }
]

// 常规路由表
export const routerConfig: RouteObject[] = [
  ...routerArray,
  {
    path: '/entry-form',
    element: (
      <PageLoading>
        <ChatterEntryForm />
      </PageLoading>
    ),
    meta: {
      title: 'Onboarding Information',
      key: 'Entry Form'
    }
  },
  {
    path: '/entry-check',
    element: (
      <PageLoading>
        <ChatterEntryCheck />
      </PageLoading>
    ),
    meta: {
      title: 'Onboarding Information',
      key: 'Entry Check'
    }
  },
  {
    path: '/active-level',
    element: (
      <PageLoading>
        <ActiveLevel />
      </PageLoading>
    ),
    meta: {
      title: 'Active Level',
      key: 'Active Level'
    }
  },
  {
    path: '/honey-level',
    element: (
      <PageLoading>
        <HoneyLevel />
      </PageLoading>
    ),
    meta: {
      title: 'Honey level',
      key: 'Honey level'
    }
  },
  {
    path: '/work-data',
    element: (
      <PageLoading>
        <WorkData />
      </PageLoading>
    ),
    meta: {
      title: 'Working Data',
      key: 'Working Data'
    }
  },
  {
    path: '/core-data',
    element: <CoreData />,
    meta: {
      title: 'core Data',
      key: 'core Data'
    }
  },
  {
    path: '/login',
    element: (
      <PageLoading>
        <Login />
      </PageLoading>
    )
  },
  {
    path: 'sd',
    element: (
      <PageLoading>
        <SalaryData />
      </PageLoading>
    ),
    meta: {
      title: '结薪数据',
      key: 'UserDiscard',
      access: 'salary_settlement_stat_index'
    }
  },
  {
    path: '/404',
    element: (
      <PageLoading>
        <Page404 />
      </PageLoading>
    )
  },
  {
    path: '*',
    element: <Navigate to='/404' />
  }
]

export default routerConfig
