const menu = [
  {
    path: 'index',
    meta: {
      title: '首页',
      key: '首页',
      access: [
        'account_index',
        'chatter_model_index',
        'chatter_index',
        'model_index',
        'tag_index',
        'tumbsup_notify_index',
        'feature_tag_index',
        'verify_code_index',
        'user_index',
        'order_index',
        'report_index',
        'system_config_index',
        'discarded_user_index',
        'suspend_popup_index',
        'audit_log_index',
        'data_operation',
        'ai_template_index',
        'app_config',
        'chatter_grab_configs',
        'profile_field_options',
        'chatter_limit_config_index'
      ]
    },
    icon: 'HomeOutlined',
    children: [
      {
        path: '/Accounts',
        meta: {
          title: 'Account',
          key: 'Account',
          access: 'account_index'
        }
      },
      {
        path: '/ChatterModels',
        meta: {
          title: 'ChatterModel',
          key: 'ChatterModel',
          access: 'chatter_model_index'
        }
      },
      {
        path: '/Chatters',
        meta: {
          title: 'Chatter',
          key: 'Chatters',
          access: 'chatter_index',
          tip: '前置勾选：Grab'
        }
      },
      {
        path: '/ChatterEntry',
        meta: {
          title: '入职信息',
          key: 'ChatterEntry',
          access: 'chatter_entry_index'
        }
      },
      {
        path: '/model',
        meta: {
          title: 'Model',
          key: 'Models',
          access: 'model_index',
          tip: '前置勾选：标签管理、FeatureTag'
        }
      },
      {
        path: '/tag',
        meta: {
          title: '标签管理',
          key: 'tag',
          access: 'tag_index'
        }
      },
      {
        path: '/plan',
        meta: {
          title: '评论计划',
          key: 'plan',
          access: 'tumbsup_notify_index'
        }
      },
      {
        path: '/feature-tag',
        meta: {
          title: 'FeatureTag',
          key: 'feature-tag',
          access: 'feature_tag_index'
        }
      },
      {
        path: '/verify-code',
        meta: {
          title: 'VerifyCode',
          key: 'verify-code',
          access: 'verify_code_index'
        }
      },
      {
        path: '/user',
        meta: {
          title: 'User',
          key: 'Users',
          access: 'user_index',
          tip: '前置勾选：标签管理、FeatureTag'
        }
      },
      {
        path: '/order',
        meta: {
          title: 'Order',
          key: 'order',
          access: 'order_index'
        }
      },
      {
        path: '/report',
        meta: {
          title: 'Report',
          key: 'Report',
          access: 'report_index'
        }
      },
      {
        path: '/system-config',
        meta: {
          title: 'SystemConfig',
          key: 'SystemConfig',
          access: 'system_config_index'
        }
      },
      {
        path: '/user-discard',
        meta: {
          title: 'UserDiscard',
          key: 'UserDiscard',
          access: 'discarded_user_index'
        }
      },
      {
        path: '/pop-up-record',
        meta: {
          title: '挂机弹窗',
          key: 'pop-up-record',
          access: 'suspend_popup_index'
        }
      },
      {
        path: '/ai-template',
        meta: {
          title: 'AI模版列表',
          key: 'ai-template',
          access: 'ai-template_index'
        }
      },
      {
        path: '/majiabao',
        meta: {
          title: '马甲包',
          key: 'majiabao',
          access: 'app_config'
        }
      },
      {
        path: '/packet-configuration',
        meta: {
          title: 'packet configuration',
          key: 'packet-configuration',
          access: 'profile_field_options'
        }
      },
      {
        path: '/log',
        meta: {
          title: '操作日志',
          key: 'log',
          access: 'audit_log_index'
        }
      },
      {
        path: '/test',
        meta: {
          title: '数据操作',
          key: 'test',
          access: 'data_operation'
        }
      },
      {
        path: '/ChatterToken',
        meta: {
          title: 'Chatter踢出登录',
          key: 'ChatterToken',
          access: 'chatter_token'
        }
      },
      // {
      //   path: '/banne',
      //   meta: {
      //     title: '封禁管理',
      //     key: 'banne',
      //     access: 'message_task_index'
      //   }
      // },
      {
        path: '/grab',
        meta: {
          title: 'Grab 固定时间配置',
          key: 'Grab',
          access: 'chatter_grab_configs'
        }
      },
      {
        path: '/traffic-distribution',
        meta: {
          title: '流量分发',
          key: 'traffic-distribution',
          access: 'chatter_limit_config_index'
        }
      }
    ]
  },
  {
    path: 'check',
    meta: {
      title: '会话审核',
      key: '会话审核',
      access: [
        'date_conversation_index',
        'normal_date_conversation_index',
        'date_conversation_audit_index',
        'ai_audit_rule_index',
        'v2_date_conversation_audit_index',
        'v2_date_conversation_audit_history_index'
      ]
    },
    icon: 'CommentOutlined',
    children: [
      {
        path: '/Conversation',
        meta: {
          title: '会话列表(Model)',
          key: 'conversation',
          access: 'date_conversation_index'
        }
      },
      {
        path: '/conversation-people',
        meta: {
          title: '会话列表(素人)',
          key: 'conversation-people',
          access: 'normal_date_conversation_index'
        }
      },
      {
        path: '/quality-inspection',
        meta: {
          title: '质检',
          key: 'quality-inspection',
          access: 'date_conversation_audit_index'
        }
      },
      {
        path: '/quality-inspection-v2',
        meta: {
          title: '质检(V2)',
          key: 'quality-inspection-v2',
          access: 'v2_date_conversation_audit_index'
        }
      },
      {
        path: '/quality-inspection-history',
        meta: {
          title: '质检修改历史',
          key: 'quality-inspection-history',
          access: 'v2_date_conversation_audit_history_index'
        }
      },
      {
        path: '/quality-inspection-ai',
        meta: {
          title: 'GPT质检规则',
          key: 'quality-inspection-ai',
          access: 'ai_audit_rule_index'
        }
      }
    ]
  },
  {
    path: 'salary',
    meta: {
      title: '薪资管理',
      key: '薪资管理',
      access: ['payroll_index']
    },
    icon: 'CommentOutlined',
    children: [
      {
        path: '/Payroll',
        meta: {
          title: '薪资条',
          key: 'payroll',
          access: 'payroll_index'
        }
      }
    ]
  },
  {
    path: 'data-product',
    meta: {
      title: '数据产品',
      key: 'data-product',
      // access: ['date_conversation_stat_index', 'online_stat_index']
      access: ['date_conversation_stat_index']
    },
    icon: 'BuildOutlined',
    children: [
      {
        path: '/data-product/salary-data-bi',
        meta: {
          title: '结薪BI',
          key: 'bi'
        }
      },
      {
        path: '/data-product/online-time-bi',
        meta: {
          title: '在线时长BI',
          key: 'online_time_bi'
        }
      },
      {
        path: '/data-product/session-data',
        meta: {
          title: '会话数据',
          key: '/data-product/session-data',
          access: 'date_conversation_stat_index'
        }
      }
      // {
      //   path: '/data-product/online-data',
      //   meta: {
      //     title: '在线数据表',
      //     key: 'online-data',
      //     access: 'online_stat_index'
      //   }
      // }
    ]
  },
  {
    path: 'train-center',
    meta: {
      title: '培训中心',
      key: 'UserDiscard',
      access: ['guide_popup_index', 'train_popup_index']
    },
    icon: 'BlockOutlined',
    children: [
      {
        path: '/train-center/guide',
        meta: {
          title: '指引弹窗',
          key: 'UserDiscard',
          access: 'guide_popup_index'
        }
      },
      {
        path: '/train-center/train',
        meta: {
          title: '培训弹窗',
          key: 'UserDiscard',
          access: 'train_popup_index'
        }
      }
    ]
  },
  {
    path: 'assistant',
    meta: {
      title: '小助手消息',
      key: 'assistant',
      access: ['message_task_index']
    },
    icon: 'CarryOutOutlined',
    children: [
      {
        path: '/assistant/instant-message',
        meta: {
          title: '即时消息',
          key: 'instant',
          access: 'message_task_index'
        }
      }
    ]
  },
  {
    path: 'qc',
    meta: {
      title: '系统引导',
      key: 'qc',
      access: ['system_config_ppv_send_limitation', 'tips_notify_configs_index', 'system_config_qc_progress']
    },
    icon: 'BellOutlined',
    children: [
      {
        path: '/ppv-send-limits',
        meta: {
          title: 'PPV发送限制',
          key: 'PpvSendLimits',
          access: 'system_config_ppv_send_limitation'
        }
      },
      {
        path: '/system-boot-prompt',
        meta: {
          title: '小灰条提醒',
          key: 'SystemBootPrompt',
          access: 'tips_notify_configs_index'
        }
      },
      {
        path: '/qc-progress-bar',
        meta: {
          title: 'QC进度条',
          key: 'QcProgressBar',
          access: 'system_config_qc_progress'
        }
      },
      {
        path: '/sku',
        meta: {
          title: 'sku',
          key: 'sku',
          access: 'skus_index'
        }
      }
    ]
  },
  {
    path: '/administrator',
    meta: {
      title: 'Administrator',
      key: 'administrator',
      access: 'administrator'
    },
    icon: 'SettingOutlined'
  }
]

export function filterRouter(routerArray: typeof menu, accessArr: string[]) {
  // 筛选出有权限的路由
  const newRoute: typeof menu = []

  routerArray.forEach((item) => {
    if (item.meta?.access) {
      // 需要检测访问权限

      if (accessArr.includes(item.meta?.access as string) || accessArr.includes('all')) {
        newRoute.push(item)
      }
    } else {
      // 不需要权限页面
      newRoute.push(item)
    }
  })
  return newRoute
}

// 有二级的必须填写进来, 并且不要带 /
export const rootSubmenuKeys = ['data-product', 'check', 'index', 'assistant', 'train-center']

export default menu
